function initDatatables() {
    opts = {
        'destroy': true,
        'language': {
            "sEmptyTable": "Nema podataka u tablici",
            "sInfo": "Prikazano _START_ do _END_ od _TOTAL_ rezultata",
            "sInfoEmpty": "Prikazano 0 do 0 od 0 rezultata",
            "sInfoFiltered": "(filtrirano iz _MAX_ ukupnih rezultata)",
            "sInfoPostFix": "",
            "sInfoThousands": ",",
            "sLengthMenu": "Prikaži _MENU_ rezultata po stranici",
            "sLoadingRecords": "Dohvaćam...",
            "sProcessing": "Obrađujem...",
            "sSearch": "Pretraži:",
            "sZeroRecords": "Ništa nije pronađeno",
            "oPaginate": {
                "sFirst": "Prva",
                "sPrevious": "Nazad",
                "sNext": "Naprijed",
                "sLast": "Zadnja"
            },
            "oAria": {
                "sSortAscending": ": aktiviraj za rastući poredak",
                "sSortDescending": ": aktiviraj za padajući poredak"
            }
        },
        'sDom': '<"row dt-head"<"col-sm-6 text-left"f><"col-sm-6 text-right"l>>rt<"dt-bottom"<"col-sm-6 text-left"i><"col-sm-6 text-right"p>>',
        'pageLength': 100,
        "responsive": true,
        'aaSorting': []
    }
    $("#app .table-search").DataTable(opts);
}

function deleteConfirmation() {
    $( ".delete-confirm" ).click(function() {
        return confirm('Jeste li sigurni?');
    });
}

function hideAlert() {
    $(".alert:not(.usual)").delay(3000).slideUp(500, function(){
        $(".alert:not(.usual)").hide();
    });
}

function initDatePicker() {
    $('.datepicker-nolimit').datepicker({
        isRTL: false,
        autoclose: true,
        language: 'hr-HR',
        autoHide: true
    });

    $('.datepicker').datepicker({
        isRTL: false,
        autoclose:true,
        language: 'hr-HR',
        autoHide: true,
        endDate: new Date()
    });

    $('.datepicker-future').datepicker({
        isRTL: false,
        autoclose: true,
        language: 'hr-HR',
        autoHide: true,
        startDate: new Date()
    });
}

function initTooltips() {
    $('[data-toggle="tooltip"]').tooltip()
}

function initSelect2() {
    $('.select2').select2({
        placeholder: "Odaberite"
    });
}

function select2CacoonInit() {
    $("#workout-creator").on("cocoon:after-insert", function(_, row){
        initSelect2();
    });
}

function clearDataTable() {
    if($("#app .table-search").length == 1) {
        $("#app .table-search").DataTable().destroy();
    }
}

function focusSelect2Text() {
    $(document).on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
    });
}

function exercisePreviewModal() {
    $('#video-preview').on('hidden.bs.modal', function () {
        $("#embed-here").html('');
    });

    $(".open-video-preview").click(function () {
        video = $(this).data('url')
        $("#embed-here").html('<iframe width="560" height="315" src="' + video + '" frameborder="0" allowfullscreen wmode="Opaque"></iframe>');
    });
}

function setRowOrderNumbers() {
    let n = 0;
    $(".counters").each(function () {
        $(this).html("#" + ++n);
    })
}

function setRowOrderNumbersOnAction() {
    $("#table-with-counters").on('draw.dt, order.dt', function () {
        setRowOrderNumbers()
    });
}

function sidebarPushToggle() {
    if ($(window).width() < 1200) {
        $('[data-widget="pushmenu"]').PushMenu("collapse");
    }
}

function initCurrencyFormat() {
    $('.currency-val').number(true, 2, ',', '.');
}

function datePickerAfterCacoon() {
    $('#installments-creator').on('cocoon:after-insert', function (e, insertedItem) {
        initDatePicker();
        initCurrencyFormat();
    });
}

function chatTriggers() {
    $("#fake-send-message").on("click", function () {
        clearChatInputField();
    });

    document.addEventListener("keydown", function (event) {
        if ($("#chat-text").is(":focus") && event.key == "Enter" && !event.shiftKey) {
            event.preventDefault();
            clearChatInputField();
        }
    });
}

function clearChatInputField() {
    $('#send-chat-message').trigger('click');
    $('#chat-text').val('');
}

function onPageRender(){
    $('.nav-link.active').parents('.nav-parent').addClass('menu-open');
}

function quickMessageIdSet() {
    $(".insta-message").on("click", function () {
      $('#receiver_id').val($(this).data('receiver-id'));
    });
}

function setMaxHeight() {
    footHeight = $(".main-footer").outerHeight();
    headHeight = $(".main-header").offset()?.top ?? 0;
    viewportHeight = $(window).height();
    $('.max-height').height(viewportHeight - headHeight - footHeight);
  }

function buttonSpinner() {
    $('.btn-with-spinner').on('click', function() {
        var submitButton = $(this);
        var spinnerIcon = submitButton.find('.btn-spinner');
        var confirmationText = submitButton.next('.btn-confirmation');

        // Show the spinner icon
        spinnerIcon.show();

        // Hide the spinner icon after 1 second (1000 milliseconds)
        setTimeout(function() {
            spinnerIcon.hide();
            confirmationText.addClass('visible');
        }, 500);
    });
}


$(document).on( "turbo:load", function() {
    initDatatables()
    deleteConfirmation()
    hideAlert()
    initDatePicker()
    initTooltips()
    initSelect2()
    select2CacoonInit()
    focusSelect2Text()
    exercisePreviewModal()
    setRowOrderNumbers()
    setRowOrderNumbersOnAction()
    sidebarPushToggle()
    initCurrencyFormat()
    datePickerAfterCacoon()
    onPageRender()
    chatTriggers()
    quickMessageIdSet()
    setMaxHeight()
    buttonSpinner()

    // after some node update modal's stopped opening so this is a hack
    $('.modal').on('shown.bs.modal', function () {
        $('.modal-backdrop').addClass('fade show');
    });
    $('.modal').on('hidden.bs.modal', function () {
        $('.modal-backdrop').removeClass('fade show');
    });

    $(document).on("turbo:frame-load", function (event) {
        if (event.target.id === "member_overview") {
            var targetModal = $('#memberOverviewModal');
            targetModal.modal('show');
        }
    });
});

$(document).on( "turbo:before-cache", function() {
    clearDataTable()
});
